<template>
  <div class="LoginApp" :class="{ logged }">
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
      <v-main class="loginAppMain">
        <CredentialForm
          class="credentialForm"
          :appName="$root.appName"
          :cognitoUser="$root.cognitoUser"
          :loadingOthers="logged"
          :api="api"
          @snackbar="$root.snackbar"
          @modal="$root.modal"
          @logged="onLogged"
          @cognito="onCognito"
          skipLocalLogin
        >
          <template v-slot:logoImage>
            <div class="logoWrapper">
              <img v-if="bLogoImage" class="logo bLogoImage" alt="Company Logo" :src="bLogoImage" />
              <img class="logo" alt="Company Logo" src="@/../../../img/goConnectClient-600px.png" />
            </div>
          </template>
        </CredentialForm>
      </v-main>
      <Dialog ref="dialog" />
      <div class="appStatusInner">
        <div class="appVersion" v-text="$root.appVersion"></div>
        <div class="apiVersionDiv" :class="apiA ? 'apiTest' : 'apiTestWaiting'">
          <v-icon small @click.prevent="apiVersion">{{ apiA ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}</v-icon>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import { sleep } from "./../../../../script/misc"
import api from "./service/api.js"
api.setup({ apiCognitoUserIgnore: true })
const urlParams = new URLSearchParams(window.location.search)
const bId = urlParams.get("bId")
export default {
  name: "LoginApp",
  data() {
    return {
      logged: false,
      appName: window.localStorage["appConnect"] || "",
      bLogoImage: "",
      api,
      apiA: false,
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light"
    },
  },
  components: {
    CredentialForm: () => import("./../../../components/CredentialForm.vue"),
    Dialog: () => import("./../../../components/Dialog.vue"),
  },
  async mounted() {
    this.apiVersion()
    if (bId) {
      const result = await api.any().getBusinessProfile(bId)
      const { bLogoImage } = result.data.bPublic
      this.bLogoImage = bLogoImage
    }
  },
  methods: {
    onCognito(cognitoUser) {
      this.$set(this.$root, "cognitoUser", cognitoUser)
    },
    async onLogged({ cognitoUser, appName }) {
      window.localStorage["appConnect"] = appName
      this.$root.appName = appName
      this.$set(this.$root, "cognitoUser", cognitoUser)

      this.logged = true
      await sleep(1000)
      this.goToAdmin()
    },
    goToAdmin() {
      window.location.href = `//${window.location.host}/admin/index.html`
    },
    async apiVersion() {
      this.apiA = false
      const result = await api.any().apiVersion()
      this.apiA = result.ok
    },
  },
}
</script>


<style lang="scss" >
@import "./../../../components/AllComponents.scss";

.LoginApp {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f9f9f9;
}

.logo {
  max-width: 75%;
  width: 350px;
  margin: auto auto 16px auto;
}

.bName {
  font-size: 26px;
  opacity: 0.9;
  max-width: 90vw;
  width: 100%;
  margin: auto;
}

.credentialForm {
  margin-top: 160px;
  margin-bottom: 20px;
}

.logoWrapper {
  width: 100%;
}

.LoginApp .v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 16px;
}

.v-application {
  .loginAppMain .CardPanel {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    padding: 0px !important;
  }
}

.v-card {
  padding: 16px;
}

.borderless {
  border: none !important;
}

.appStatusInner {
  margin-left: 5px;
  display: inline-flex;
  line-height: 19px;
  .appVersion {
    opacity: 0.5;
    font-size: 13px;
    margin-right: 10px;
  }
  .apiVersionDiv {
    pointer-events: none;
  }
}
</style>
