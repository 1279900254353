"use strict"

exports.appTypeClient = "client"
exports.appTypeBusiness = "business"
exports.appTypeLogin = "login"

exports.avStatusSafe = "safe"
exports.avStatusUnsafe = "unsafe"

exports.statusPending = "pending"
exports.statusDone = "done"

exports.userStatus = {
  usActive: "active", //users that have already logged at least once
  usPending: "pending", //created or imported user, but invitation was not sent.
  usInvited: "invited", //users that have received the invitation email with their credentials
  // usDisabled: "disabled", // there's no disabled status. The user is marked has uDisabled set to true
}

exports.clientUserTypes = {
  cutIndividual: "individual", //ClientUser individual type
  cutCompany: "company", //ClientUser Company type
  cutLinked: "linked", //users linked to a ClientUser type Company
}

/** represent the initial state of createdAt. Before save the data on DB it should be updated */
exports.createdAtZero = 0

/** it should be used to indicate a unset value on properties that require a value like a DynamoDB hash key */
exports.valueUnset = "unset"

/** a reference/ link class to a ClientUser or BusinessUser class */
class LinkedUser {

  /**
   * @param {Object} [data] 
   * @param {String} data.email user email
   * @param {String} data.cognitoId user cognitoId
   * @param {String} data.luName a name to identify this linked user. It may be different than the uName
   * @param {String} data.luStatus user status/error during the invitation
   */
  constructor(data) {
    if (!data) data = {}
    this.email = String(data.email || "").trim().toLowerCase()
    this.cognitoId = data.cognitoId || ""
    this.luName = String(data.luName || "").trim()
    this.luStatus = data.luStatus
  }
}
exports.LinkedUser = LinkedUser

/** Any user should at the following properties as they used on Hash and Range keys */
class BaseUser {

  /**
   * @param {Object} [data] 
   * @param {String} data.businessId 
   * @param {String} data.email 
   * @param {String} data.cognitoId 
   * @param {String} data.createdAt 
   * @param {String} data.updatedAt can prevent an out of date data updates a newer data 
   * @param {Array<String>} data.invitedAt 
   * @param {String} data.cognitoFirstLoginAt
   * @param {Boolean} data.uDisabled 
   */
  constructor(data) {
    if (!data) data = {}
    this.businessId = data.businessId || ""
    this.email = String(data.email || "").trim().toLowerCase()
    this.cognitoId = data.cognitoId || ""
    this.createdAt = data.createdAt || exports.createdAtZero
    this.updatedAt = data.updatedAt || exports.createdAtZero
    this.invitedAt = data.invitedAt || []
    this.cognitoFirstLoginAt = data.cognitoFirstLoginAt || exports.createdAtZero
    this.uDisabled = Boolean(data.uDisabled)
  }
}

/**
 * Class to Business Users 
 * @extends BaseUser
 */
class BusinessUser extends BaseUser {

  /**
   * @param {Object} [data] 
   * @param {String} data.businessId 
   * @param {String} data.email 
   * @param {String} data.cognitoId 
   * @param {String} data.cognitoFirstLoginAt
   * @param {Boolean} data.uDisabled 
   * @param {String} data.createdAt 
   * @param {Array<String>} data.invitedAt 
   * @param {String} data.uName 
   * @param {String} data.uPhone 
   * @param {String} data.uBio 
   * @param {Object} data.uOrganization 
   * @param {String} data.uOrganization.uDepartment 
   * @param {String} data.uOrganization.uPosition
   * @param {String} data.uProfileImage 
   * @param {String} data.uStatus 
   */
  constructor(data) {
    if (!data) data = {}
    super(data)
    this.uName = String(data.uName || "").trim()
    this.uPhone = String(data.uPhone || "").trim()
    this.uBio = String(data.uBio || "").trim()
    this.uOrganization = {
      uDepartment: data.uOrganization?.uDepartment || "",
      uPosition: data.uOrganization?.uPosition || ""
    }
    this.uProfileImage = data.uProfileImage || ""
    this.uStatus = data.uStatus || exports.userStatus.usPending
  }

}
exports.BusinessUser = BusinessUser

/**
 * Business Client User class. This class contains data from the Business related to a single client. The data might be sensitive, may or may not be exposed to Client.
 * @extends BaseUser
 */
class ClientUser extends BaseUser {

  /**
   * @param {Object} [data] 
   * @param {String} data.businessId 
   * @param {String} data.cognitoId 
   * @param {String} data.email 
   * @param {String} data.cognitoFirstLoginAt 
   * @param {Boolean} data.uDisabled
   * @param {String} data.createdAt 
   * @param {Array<String>} data.invitedAt 
   * @param {String} data.uType types found in clientUserTypes
   * @param {String} data.uName 
   * @param {String} data.uPhone 
   * @param {String} data.uAddress 
   * @param {String} data.uPostcode 
   * @param {String} data.uProfileImage 
   * @param {String} data.uGender 
   * @param {String} data.uTaxNum //PPS or Tax Number
   * @param {String} data.uDOB 
   * @param {String} data.uNationality 
   * @param {String} data.uMaritalStatus 
   * @param {String} data.uDateOfMarriage Date of Marriage/ divorce/ separation/ widow 
   * @param {String} data.uLastMessageAt Date a last message was sent/received by this client 
   * @param {String} data.uLastMessageFromClientAt Date a last message was sent by this client 
   * @param {Array<AMLIndividual>} data.uAMLIndividuals  
   * @param {String} data.uAMLStatus  
   * @param {String} data.uAMLStatusUpdatedAt  
   * @param {Array<LinkedUser>} data.uLinkedUsers users that are linked
   * @param {Array<String>} data.uLinkedMemberIds list of members linked to this client
   * @param {String} data.uLinkedCompanyId access to Client user id (type Company)
   * @param {String} data.uSpouse.uName 
   * @param {String} data.uSpouse.uNamePreMarriage 
   * @param {String} data.uSpouse.uGender 
   * @param {String} data.uSpouse.uTaxNum 
   * @param {String} data.uSpouse.uDOB 
   * @param {String} data.uSpouse.uPhone
   * @param {String} data.uSpouse.uNationality
   * @param {Object} data.uReadonly data that can only be updated by the Business. Client can only see it. 
   * @param {String} data.uReadonly.uReference data that can only be updated by the Business. Client can only see it. 
   * @param {String} data.uReadonly.uStatus data that can only be updated by the Business. Client can only see it. 
   * @param {Object} data.uPrivate data that will only be visible and updatable by the Business. Client won't have any access to it. 
   * @param {String} data.uPrivate.uNote 
   * @param {Array<String>} data.uPrivate.uTags 
   * @param {Boolean} uPrivateShow indicate if uPrivate will be filled
   */
  constructor(data, uPrivateShow = true) {
    if (!data) data = {}
    super(data)
    this.uType = String(data.uType || exports.clientUserTypes.cutIndividual).trim()
    this.uName = String(data.uName || "").trim()
    this.uPhone = String(data.uPhone || "").trim()
    this.uAddress = String(data.uAddress || "").trim()
    this.uPostcode = String(data.uPostcode || "").trim()
    this.uProfileImage = String(data.uProfileImage || "").trim()
    this.uGender = String(data.uGender || "").trim()
    this.uTaxNum = String(data.uTaxNum || "").trim()
    this.uDOB = String(data.uDOB || "").trim()
    this.uNationality = String(data.uNationality || "").trim()
    this.uMaritalStatus = String(data.uMaritalStatus || "").trim()
    this.uDateOfMarriage = String(data.uDateOfMarriage || "").trim()
    this.uLastMessageAt = String(data.uLastMessageAt || "").trim()
    this.uLastMessageFromClientAt = String(data.uLastMessageFromClientAt || "").trim()
    this.uAMLIndividuals = (data.uAMLIndividuals || []).map(individual => new AMLIndividual(individual))
    this.uAMLStatus = "" //generateAMLStatus will fill the uAMLStatus
    this.uAMLStatusUpdatedAt = data.uAMLStatusUpdatedAt || exports.createdAtZero
    this.uLinkedMemberIds = data.uLinkedMemberIds || []
    this.uLinkedUsers = data.uLinkedUsers || []
    this.uLinkedCompanyId = String(data.uLinkedCompanyId || "").trim()

    this.uReadonly = {
      uReference: String(data.uReadonly?.uReference || "").trim(),
      uStatus: data.uReadonly?.uStatus || exports.userStatus.usPending
    }
    this.uPrivate = {
      uTags: data.uPrivate?.uTags || [],
      uNote: String(data.uPrivate?.uNote || "").trim(),
    }

    this.uSpouse = {
      uName: String(data.uSpouse?.uName || "").trim(),
      uNamePreMarriage: String(data.uSpouse?.uNamePreMarriage || "").trim(),
      uGender: String(data.uSpouse?.uGender || "").trim(),
      uTaxNum: String(data.uSpouse?.uTaxNum || "").trim(),
      uDOB: String(data.uSpouse?.uDOB || "").trim(),
      uNationality: String(data.uSpouse?.uNationality || "").trim(),
      uPhone: String(data.uSpouse?.uPhone || "").trim(),
    }

    if (!uPrivateShow)
      this.uPrivate = undefined

    this.generateAMLStatus()
  }

  generateAMLStatus() {
    if (this.uAMLIndividuals.length === 0)
      this.uAMLStatus = exports.AMLStatusOutstanding
    else {
      const anyAMLIndividualDocUploaded = this.uAMLIndividuals.some((individual) => AMLIndividual.hasAMLIndividualDocUploaded(individual.indDocs))
      if (!anyAMLIndividualDocUploaded)
        this.uAMLStatus = exports.AMLStatusOutstanding
      else {
        let idocEvalRejectedCount = 0
        let idocEvalApprovedCount = 0
        let idocEvalEmptyCount = 0
        this.uAMLIndividuals.forEach(individual => {
          Object.keys(individual.indDocs).forEach(idocProp => {
            const { idocEval, idocName } = individual.indDocs[idocProp]

            if (!idocName)
              return idocEvalRejectedCount += 1

            switch (idocEval) {
              case exports.idocEvalRejected:
                idocEvalRejectedCount += 1
                break
              case exports.idocEvalApproved:
                idocEvalApprovedCount += 1
                individual.indDocs[idocProp].idocNote = ""
                break
              default:
                idocEvalEmptyCount += 1
            }
          })
        })

        this.uAMLStatus = exports.AMLStatusGreen
        if (idocEvalRejectedCount > 0)
          this.uAMLStatus = exports.AMLStatusRed
        else if (idocEvalEmptyCount > 0)
          this.uAMLStatus = exports.AMLStatusOrange
      }
    }

  }


  static hasAMLIndividualDocUploaded = (uAMLIndividuals) => {
    if (!uAMLIndividuals?.length)
      return false
    return uAMLIndividuals.some((individual) => {
      return Object.keys(individual.indDocs).some((indDocProp) => {
        const indDoc = individual.indDocs[indDocProp]
        return indDoc.idocUploadedAt === exports.valueUnset
      })
    })
  }

  static isCandidateUser = cognitoId => {
    return cognitoId.includes(exports.candidatePrefix)
  }
}
exports.ClientUser = ClientUser

exports.bAMLReminderFrequencyOptionWeekly = "weekly"
exports.bAMLReminderFrequencyOptionMonthly = "monthly"
exports.bAMLReminderFrequencyOptions = [exports.bAMLReminderFrequencyOptionWeekly, exports.bAMLReminderFrequencyOptionMonthly]

/** Business Profile class.  */
class BusinessProfile {

  /**
   * @param {Object} [data] 
   * @param {String} data.createdAt 
   * @param {String} data.updatedAt 
   * @param {String} data.businessId 
   * @param {String} data.bId 
   * @param {String} data.bUrl business custom Url 
   * @param {String} data.bUrlDone allow business to set custom Url 
   * @param {Object} data.bPublic 
   * @param {String} data.bPublic.bName 
   * @param {String} data.bPublic.bPhone 
   * @param {String} data.bPublic.bEmail 
   * @param {Object} data.bPublic.bLocation 
   * @param {String} data.bPublic.bLocation.address 
   * @param {String} data.bPublic.bLocation.addressComplement 
   * @param {String} data.bPublic.bLocation.city 
   * @param {String} data.bPublic.bLocation.country 
   * @param {String} data.bPublic.bLocation.zipcode 
   * @param {Object} data.bPublic.bSwoofee 
   * @param {String} data.bPublic.bSwoofee.swUrl 
   * @param {Object} data.bPublic.bTaxConnect 
   * @param {String} data.bPublic.bTaxConnect.txcUrl 
   * @param {Object} data.bPrivate 
   * @param {Object} data.bPrivate.bOrganization 
   * @param {[String]} data.bPrivate.bOrganization.bDepartments 
   * @param {[String]} data.bPrivate.bOrganization.bPositions 
   * @param {Array<Tag>} data.bPrivate.bTagList 
   * @param {Object} data.bClientCommunication 
   * @param {Boolean} data.bClientCommunication.directEmailToBusinessEmail 
   * @param {Boolean} data.bClientCommunication.requireClientSpecifyLinkedMember 
   * @param {Object} data.bSettings 
   * @param {Boolean} data.bSettings.activeAML activeAML Deprecated. Used on v1. Replaced to bAMLConfig.bAMLActive to v2. It will be removed soon.
   * @param {Boolean} data.bSettings.bRelevanceScoreActive
   * @param {Object} data.bCustomLayout
   * @param {Boolean} data.bCustomLayout.bclActive
   * @param {String} data.bCustomLayout.bclAppStyle
   * @param {String} data.bCustomLayout.bclPrimaryColor
   * @param {String} data.bCustomLayout.bclCardHeaderBackgroundColor
   * @param {Object} data.bAMLConfig AML configuration
   * @param {Boolean} data.bAMLConfig.bAMLActive AML is displayed for the business
   * @param {Boolean} data.bAMLConfig.bAMLReminderActive enable if inform should keep receiving the AML notifications after the first notification
   * @param {String} data.bAMLConfig.bAMLReminderFrequency how ofter the reminder should run. See bAMLReminderFrequencyOptions
   */
  constructor(data) {
    if (!data) data = {}
    this.createdAt = data.createdAt || exports.createdAtZero
    this.updatedAt = data.updatedAt || exports.createdAtZero
    this.businessId = data.businessId || ""
    this.bId = data.bId || ""
    this.bUrl = data.bUrl || ""
    this.bUrlDone = Boolean(data.bUrlDone)
    this.bClientCommunication = {
      directEmailToBusinessEmail: data?.bClientCommunication?.directEmailToBusinessEmail === undefined ? true : Boolean(data?.bClientCommunication?.directEmailToBusinessEmail),
      requireClientSpecifyLinkedMember: Boolean(data?.bClientCommunication?.requireClientSpecifyLinkedMember),
    }
    this.bPublic = {
      bName: String(data.bPublic?.bName || "").trim(),
      bPhone: String(data.bPublic?.bPhone || "").trim(),
      bEmail: String(data.bPublic?.bEmail || "").trim(),
      bLocation: {
        address: String(data.bPublic?.bLocation?.address || "").trim(),
        addressComplement: String(data.bPublic?.bLocation?.addressComplement || "").trim(),
        city: String(data.bPublic?.bLocation?.city || "").trim(),
        country: String(data.bPublic?.bLocation?.country || "").trim(),
        zipcode: String(data.bPublic?.bLocation?.zipcode || "").trim(),
      },
      bSwoofee: {
        swUrl: String(data.bPublic?.bSwoofee?.swUrl || "").trim(),
      },
      bTaxConnect: {
        txcUrl: String(data.bPublic?.bTaxConnect?.txcUrl || "").trim(),
      },
      bLogoImage: data?.bPublic?.bLogoImage || ""
    }
    this.bPrivate = {
      bTagList: (data?.bPrivate?.bTagList || []).map(tag => new Tag(tag)),
      bOrganization: {
        bDepartments: data.bPrivate?.bOrganization?.bDepartments || [],
        bPositions: data.bPrivate?.bOrganization?.bPositions || []
      },
    }
    this.bSettings = {
      activeAML: Boolean(data?.bSettings?.activeAML),
      bRelevanceScoreActive: Boolean(data?.bSettings?.bRelevanceScoreActive),
    }
    this.bCustomLayout = {
      bclActive: Boolean(data?.bCustomLayout?.bclActive),
      bclAppStyle: data?.bCustomLayout?.bclAppStyle || BusinessProfile.bclAppStyleDefault,
      bclPrimaryColor: data?.bCustomLayout?.bclPrimaryColor || BusinessProfile.bclPrimaryColorDefault,
      bclCardHeaderBackgroundColor: data?.bCustomLayout?.bclCardHeaderBackgroundColor || BusinessProfile.bclCardHeaderBackgroundColorDefault,
    }
    this.bAMLConfig = {
      bAMLActive: Boolean(data?.bAMLConfig?.bAMLActive),
      bAMLReminderActive: Boolean(data?.bAMLConfig?.bAMLReminderActive),
      bAMLReminderFrequency: data?.bAMLConfig?.bAMLReminderFrequency || exports.bAMLReminderFrequencyOptionMonthly,
    }

    if (!this.bAMLConfig.bAMLActive)
      this.bAMLConfig.bAMLReminderActive = false

  }

  static bIdPrefix = "bId-"
  static bclAppStyleLightWindy = "appStyleLightWindy"
  static bclAppStyleBlockPanels = "appStyleBlockPanels"
  static bclAppStyleDefault = BusinessProfile.bclAppStyleLightWindy
  static bclPrimaryColorDefault = "#1E88E5" //blue
  static bclCardHeaderBackgroundColorDefault = "#0062B8" //dark-blue

  bclRestoreDefaults() {
    this.bCustomLayout.bclAppStyle = BusinessProfile.bclAppStyleDefault
    this.bCustomLayout.bclPrimaryColor = BusinessProfile.bclPrimaryColorDefault
    this.bCustomLayout.bclCardHeaderBackgroundColor = BusinessProfile.bclCardHeaderBackgroundColorDefault
  }

  static bUrlMinLength = 4
  static bUrlMaxLength = 50
  /** remove all non alphanumeric characters and convert to lowercase
   * @param {String} bUrl */
  static bUrlFormatter = (bUrl) => {
    bUrl = bUrl.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase()
    let error = ""
    if (bUrl.length < BusinessProfile.bUrlMinLength || bUrl.length > BusinessProfile.bUrlMaxLength)
      error = "The URL must have between 4 and 50 characters."

    return { bUrl, error }
  }
}
exports.BusinessProfile = BusinessProfile

/** 
 * EntityTrigger class summarize the entity that initiate an event. 
 * Initiators could be BusinessUser, ClientUser and BusinessProfile 
 */
class EntityTrigger {
  /**
   * @param {Object} data
   * @param {String} data.appType user type - Business or Client
   * @param {String} data.etId user cognitoId or businessId
   * @param {String} data.etName name
   * @param {String} data.etEmail email
  */
  constructor(data) {
    if (!data) data = {}
    this.appType = data.appType || ""
    this.etId = data.etId || ""
    this.etName = data.etName || ""
    this.etEmail = data.etEmail || ""
  }

  static getNameAndEmail = (entityTrigger) => {
    return `${entityTrigger.etName} (${entityTrigger.etEmail})`
  }
}
exports.EntityTrigger = EntityTrigger

exports.candidatePrefix = "candidate--"

exports.createCandidateId = () => exports.candidatePrefix + Math.floor(Math.random() * Math.random() * Date.now()).toString(16) //temporary id

class Tag {

  /**
   * @param {Object} [data]  
   * @param {String} data.tagId 
   * @param {String} data.tagName 
   * @param {String} data.tagDesc 
   * @param {Object} data.tagUseCase
   * @param {Object} data.tagColour 
   */
  constructor(data) {
    if (!data) data = {}
    this.tagId = data.tagId || ""
    this.tagName = data.tagName ? Tag.tagNameFormat(data.tagName) : ""
    this.tagDesc = String(data.tagDesc || "").trim()
    this.tagUseCase = String(data.tagUseCase || "").trim()
    this.tagColour = data.tagColour || ""
  }

  tagIdNew(uuidValue) {
    if (!uuidValue)
      throw new Error("uuidValue is required")
    this.tagId = `tagId-${uuidValue}`
  }

  static tagNameFormat(tagName) {
    const space = "SPACE"
    return tagName.toLowerCase().trim()
      .replace(new RegExp("-", 'g'), space) //replace all dashes to single space.
      .replace(/\s+/g, space) //replace all spacing for the word SPACE.
      .replace(/[\W_]+/g, "") //replace all non alphanumeric characters to nothing
      .replace(new RegExp(space, 'g'), " ") //replace all SPACE words to single space.
      .replace(/\s+/g, "-") //replace single space to dash
  }
}
exports.Tag = Tag
exports.newTagIdPrefix = "newTag_"

class SearchingTag {
  /**
   * @param {Object} data
   * @param {String} data.pkLookup 
   * @param {String} data.skLookup  
   * @param {Array<TaggedItem>} data.taggedItems    
   */
  constructor(data) {
    if (!data) data = {}
    this.pkLookup = data.pkLookup || ""
    this.skLookup = data.skLookup || ""
    this.taggedItems = data.taggedItems || []
  }
}
exports.SearchingTag = SearchingTag

class TaggedItem {

  /**
   * @param {Object} data
   * @param {String} data.tiId //tagged item Id. E.g. client id or file Id
   * @param {String} data.tiContext  //tagged item context. Eg. Client or File
   */
  constructor(data) {
    if (!data) data = {}
    this.tiId = data.tiId || ""
    this.tiContext = data.tiContext || ""
  }

}
exports.TaggedItem = TaggedItem

class AMLIndividual {
  /**
   * @param {Object} [data] 
   * @param {String} data.indId  
   * @param {Object} data.indDocs  
   * @param {AMLIndividualDoc} data.indDocs.indPhotoDoc1  
   * @param {AMLIndividualDoc} data.indDocs.indPhotoDoc2 
   * @param {AMLIndividualDoc} data.indDocs.indAddressDoc  
   */
  constructor(data) {
    if (!data) data = {}
    this.indId = data.indId || ""
    this.indDocs = {
      indPhotoDoc1: new AMLIndividualDoc(data?.indDocs?.indPhotoDoc1),
      indPhotoDoc2: new AMLIndividualDoc(data?.indDocs?.indPhotoDoc2),
      indAddressDoc: new AMLIndividualDoc(data?.indDocs?.indAddressDoc),
    }
  }

  static indDocProps = ["indPhotoDoc1", "indPhotoDoc2", "indAddressDoc"]

  static hasAMLIndividualDocUploaded = (indDocs) => {
    if (!indDocs)
      return false
    return AMLIndividual.indDocProps.some((indDocProp) => indDocs[indDocProp].idocName)
  }
}
exports.AMLIndividual = AMLIndividual

exports.AMLStatusNA = "AMLStatusNA"
exports.AMLStatusOutstanding = "AMLStatusOutstanding"
exports.AMLStatusGreen = "AMLStatusCompliant"
exports.AMLStatusOrange = "AMLStatusReview"
exports.AMLStatusRed = "AMLStatusFail"

class AMLIndividualDoc {
  /**
   * @param {Object} [data] 
   * @param {String} data.idocType type of document 
   * @param {String} data.idocName document name
   * @param {String} data.idocEval document evaluation (Empty, idocEvalRejected or idocEvalApproved) 
   * @param {String} data.idocNote 
   * @param {String} data.idocUploadedAt
   * @param {Object} data.idocEntities relevant content extracted from the document
   */
  constructor(data) {
    if (!data) data = {}
    this.idocType = data.idocType || ""
    this.idocName = data.idocName || ""
    this.idocEval = data.idocEval || ""
    this.idocNote = data.idocNote || ""
    this.idocUploadedAt = data.idocUploadedAt || ""
    this.idocEntities = data.idocEntities || {}
  }

  /**
   * convert a AMLIndividualDoc to expected data structure based on idocType
   * @param {Object} params 
   * @param {String} params.idocType document type id from photoDocTypes or addressDocTypes
   * @param {Object} params.setEntityValues values extracted 
  */
  static getAMLEntitiesDetailedDataStructured({ idocType, setEntityValues }) {

    /** @param {Array<String>} props */
    function buildStructure(props) {
      const dataStructured = {
        type: {
          entId: "entId-docType",
          entLabel: "Document type",
          entType: "text",
          entValue: ""
        },
        name: {
          entId: "entId-name",
          entLabel: "Name",
          entType: "text",
          entValue: ""
        },
        placeOfBirth: {
          entId: "entId-placeOfBirth",
          entLabel: "Place of birth",
          entType: "text",
          entValue: ""
        },
        expiryDate: {
          entId: "entId-expiryDate",
          entLabel: "Expire date",
          entType: "date",
          entValue: ""
        },
        dob: {
          entId: "entId-dob",
          entLabel: "Day of birth",
          entType: "date",
          entValue: ""
        },
        address: {
          entId: "entId-address",
          entLabel: "Address",
          entType: "text",
          entValue: ""
        },
        dateIssued: {
          entId: "entId-dateIssued",
          entLabel: "Date of issue",
          entType: "date",
          entValue: ""
        }
      }

      return props.reduce((prev, prop) => ({ ...prev, [prop]: dataStructured[prop] }), {})
    }

    let dataStructured = {}
    switch (idocType) {
      case exports.photoDocTypePassport.pdId:
      case exports.photoDocTypeSelfie.pdId:
        dataStructured = buildStructure(["type", "name", "placeOfBirth", "expiryDate", "dob"])
        break
      case exports.photoDocTypeDrivingLicence.pdId:
        dataStructured = buildStructure(["type", "name", "address", "expiryDate", "dob"])
        break
      case exports.photoAddressTypeFinancialInstitution.paId:
      case exports.photoAddressUtilityBill.paId:
      case exports.photoAddressMotorTax.paId:
      case exports.photoAddressInsuranceRenewal.paId:
      case exports.photoAddressPropertyTax.paId:
      case exports.photoAddressMortgageStatement.paId:
        dataStructured = buildStructure(["type", "name", "address", "dateIssued"])
        break
    }

    if (setEntityValues && Object.keys(setEntityValues).length > 0)
      Object.keys(dataStructured).forEach(dataProp => dataStructured[dataProp].entValue = setEntityValues[dataProp] || "")

    return dataStructured
  }

  /**
   * convert a AMLIndividualDoc to expected data structure based on idocType
   * @param {Object} params 
   * @param {String} params.idocType document type id from photoDocTypes or addressDocTypes
   * @param {Object} params.setEntityValues values extracted 
   */
  static getAMLEntitiesBasicDataStructured({ idocType, setEntityValues }) {

    const detailedDataStructured = this.getAMLEntitiesDetailedDataStructured({ idocType, setEntityValues })
    const dataResult = {}
    Object.keys(detailedDataStructured).forEach(dataPropName => dataResult[dataPropName] = detailedDataStructured[dataPropName].entValue)

    return dataResult
  }

}
exports.AMLIndividualDoc = AMLIndividualDoc

exports.genderOptionMale = {
  genId: "7b80e1e8",
  genCaption: "Male"
}
exports.genderOptionFemale = {
  genId: "2e070a9c",
  genCaption: "Female"
}
exports.genderOptions = [exports.genderOptionMale, exports.genderOptionFemale]

exports.maritalOptionSingle = {
  marId: "1823915d",
  marCaption: "Single"
}
exports.maritalOptionMarried = {
  marId: "c5b87c47",
  marCaption: "Married"
}
exports.maritalOptionInCivilPartnership = {
  marId: "65ff0e1e",
  marCaption: "In a Civil Partnership"
}
exports.maritalOptionSeparated = {
  marId: "268d9458",
  marCaption: "Separated"
}
exports.maritalOptionSeparatedCivilPartnership = {
  marId: "6ceabe61",
  marCaption: "Separated Civil Partnership"
}
exports.maritalOptionSingleParent = {
  marId: "553ea0c1",
  marCaption: "Single Parent"
}
exports.maritalOptionWindowed = {
  marId: "42bcf348",
  marCaption: "Widowed"
}
exports.maritalOptionWidowedCivilPartnership = {
  marId: "1a595cbd",
  marCaption: "Widowed Civil Partnership"
}
exports.maritalOptionDivorced = {
  marId: "a8e7b321",
  marCaption: "Divorced"
}
exports.maritalOptionDivorcedCivilPartnership = {
  marId: "ae00f8af",
  marCaption: "Divorced Civil Partnership"
}

exports.maritalOptions = [
  exports.maritalOptionSingle,
  exports.maritalOptionMarried,
  exports.maritalOptionInCivilPartnership,
  exports.maritalOptionSeparated,
  exports.maritalOptionSeparatedCivilPartnership,
  exports.maritalOptionSingleParent,
  exports.maritalOptionWindowed,
  exports.maritalOptionWidowedCivilPartnership,
  exports.maritalOptionDivorced,
  exports.maritalOptionDivorcedCivilPartnership,
]

exports.photoDocTypePassport = {
  pdId: "passport",
  pdCaption: "Passport / Passport Card",
}

exports.photoDocTypeDrivingLicence = {
  pdId: "drivingLicence",
  pdCaption: "Driving Licence",
}
exports.photoDocTypeSelfie = {
  pdId: "selfieDoc",
  pdCaption: "Selfie",
}

exports.photoDocTypes = [
  exports.photoDocTypePassport,
  exports.photoDocTypeDrivingLicence,
  exports.photoDocTypeSelfie,
]

exports.photoAddressTypeFinancialInstitution = {
  paId: "financialInstitution",
  paCaption: "Statements from Financial Institutions",
}
exports.photoAddressUtilityBill = {
  paId: "utilityBill",
  paCaption: "Utility Bill and Connection Letters",
}
exports.photoAddressMotorTax = {
  paId: "drivingMotorTax",
  paCaption: "Motor Tax Documents",
}
exports.photoAddressInsuranceRenewal = {
  paId: "insuranceRenewal",
  paCaption: "Insurance Renewal Documents (Home and Health Insurance)",
}
exports.photoAddressPropertyTax = {
  paId: "propertyTax",
  paCaption: "Property Tax Documents",
}
exports.photoAddressMortgageStatement = {
  paId: "mortgageStatement",
  paCaption: "Mortgage Statement",
}

exports.addressDocTypes = [
  exports.photoAddressTypeFinancialInstitution,
  exports.photoAddressUtilityBill,
  exports.photoAddressMotorTax,
  exports.photoAddressInsuranceRenewal,
  exports.photoAddressPropertyTax,
  exports.photoAddressMortgageStatement,
]

exports.idocEvalRejected = "rejected"
exports.idocEvalApproved = "approved"

class SnagLookupAMLStatusData {
  /**
   * @param {Object} [data] 
   * @param {String} data.createdAt 
   * @param {String} data.updatedAt 
   * @param {String} data.pkLookup pkLookup AMLStatusRed will allow cron job find quickly the relevant status
   * @param {String} data.skLookup skLookup with businessId allow each company report find easily their own data
   * @param {String} data.businessId businessId helps cron job to easily find the company related to this snag data 
   * @param {String} data.cognitoId  
   * @param {String} data.lastTimeNotified  
   */
  constructor(data) {
    if (!data) data = {}
    this.createdAt = data.createdAt || ""
    this.updatedAt = data.updatedAt || ""
    this.pkLookup = data.pkLookup || ""
    this.skLookup = data.skLookup || ""
    this.businessId = data.businessId || ""
    this.cognitoId = data.cognitoId || ""
    this.lastTimeNotified = data.lastTimeNotified || ""
  }
}
exports.SnagLookupAMLStatusData = SnagLookupAMLStatusData

/** Licensing Base properties */
class LicensingBase {

  /**
   * @param {Object} [data] 
   * @param {String} data.createdAt 
   * @param {String} data.updatedAt 
   * @param {String} data.licContext 
   * @param {String} data.licId 
   */
  constructor(data) {
    if (!data) data = {}
    this.createdAt = data.createdAt || exports.createdAtZero
    this.updatedAt = data.updatedAt || exports.createdAtZero
    this.licContext = data.licContext || ""
    this.licId = data.licId || ""
  }

}
exports.LicensingBase = LicensingBase

/** Licensing Business Data contains configuration for each businessProfile */
class LicensingBusiness extends LicensingBase {

  /**
   * @param {Object} [data] 
   * @param {String} data.createdAt 
   * @param {String} data.updatedAt 
   * @param {String} data.licContext 
   * @param {String} data.licId 
   * @param {String} data.licBusinessId
   * @param {String} data.licBusinessSignUpEmail
   * @param {String} data.licBusinessName
   * @param {String} data.licBusinessPhone
   * @param {String} data.licBusinessAddress
   * @param {String} data.licBusinessContactName
   * @param {Boolean} data.licBusinessActive
   * @param {String} data.licNotes 
   * @param {Number} data.licClientInvQtyLimit
   * @param {Number} data.licClientInvQtySent
   */
  constructor(data) {
    if (!data) data = {}
    super(data)
    this.licContext = LicensingBusiness.licensingBusinessContext
    this.licBusinessId = data.licBusinessId || ""
    this.licBusinessSignUpEmail = String(data.licBusinessSignUpEmail || "").toLowerCase().trim()
    this.licBusinessName = String(data.licBusinessName || "").trim()
    this.licBusinessPhone = String(data.licBusinessPhone || "").trim()
    this.licBusinessAddress = String(data.licBusinessAddress || "").trim()
    this.licBusinessContactName = String(data.licBusinessContactName || "").trim()
    this.licBusinessActive = Boolean(data.licBusinessActive)
    this.licNotes = String(data.licNotes || "").trim()
    this.licClientInvQtyLimit = data.licClientInvQtyLimit || 10
    this.licClientInvQtySent = data.licClientInvQtySent || 0
  }

  static licIdByEmail(email) {
    return `emailId-${email}`
  }

  static licIdByBusinessId(businessId) {
    return `businessId-${businessId}`
  }

  static licensingBusinessContext = "licBusiness"
}
exports.LicensingBusiness = LicensingBusiness

/** Emails that have to Licensing app */
class LicensingAccessEmail extends LicensingBase {

  /**
   * @param {Object} [data] 
   * @param {String} data.createdAt 
   * @param {String} data.updatedAt 
   * @param {String} data.licContext 
   * @param {String} data.licId 
   * @param {String} data.licAccessEmailList
   */
  constructor(data) {
    if (!data) data = {}
    super(data)
    this.licContext = LicensingAccessEmail.licensingAccessEmailContext
    this.licId = LicensingAccessEmail.licensingAccessEmailContext
    this.licAccessEmailList = data.licAccessEmailList || []
  }

  static licensingAccessEmailContext = "licAccessEmail"
}
exports.LicensingAccessEmail = LicensingAccessEmail

class S3ServiceLogging {

  /**
   * @param {Object} params 
   * @param {String} params.buckerOwner 
   * @param {String} params.bucket
   * @param {String} params.timeStamp
   * @param {String} params.remoteIP
   * @param {String} params.requester
   * @param {String} params.requestID
   * @param {String} params.operation
   * @param {String} params.key
   * @param {String} params.requestURI
   * @param {String} params.httpStatus
   * @param {String} params.errorCode
   * @param {String} params.bytesSent
   * @param {String} params.objectSize
   * @param {String} params.totalTime
   * @param {String} params.turnAroundTime
   * @param {String} params.referrer
   * @param {String} params.userAgent
   * @param {String} params.versionId 
   */
  constructor(params) {
    if (!params) params = {}
    this.buckerOwner = params.buckerOwner || ""
    this.bucket = params.bucket || ""
    this.timeStamp = params.timeStamp || ""
    this.remoteIP = params.remoteIP || ""
    this.requester = params.requester || ""
    this.requestID = params.requestID || ""
    this.operation = params.operation || ""
    this.key = params.key || ""
    this.requestURI = params.requestURI || ""
    this.httpStatus = params.httpStatus || ""
    this.errorCode = params.errorCode || ""
    this.bytesSent = params.bytesSent || ""
    this.objectSize = params.objectSize || ""
    this.totalTime = params.totalTime || ""
    this.turnAroundTime = params.turnAroundTime || ""
    this.referrer = params.referrer || ""
    this.userAgent = params.userAgent || ""
    this.versionId = params.versionId || ""
  }

  static newObjectFromText(text) {
    // Handle the time property enclosed in brackets and quoted strings
    const valueList = text.match(/\[([^\]]+)\]|(".*?"|[^"\s]+)/g).map(value => value.replace(/[\[\]"]/g, ''))
    const s3ServiceLogging = new S3ServiceLogging()
    Object.keys(s3ServiceLogging).forEach((prop, index) => {
      s3ServiceLogging[prop] = valueList[index] || null
    })
    return s3ServiceLogging
  }
}

exports.S3ServiceLogging = S3ServiceLogging

exports.appRequiresUpdate = "app-requires-update"
exports.appRequireLogin = "app-requires-login"