"use strict"

/** async sleep  */
exports.sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

/** function retryFn until hit attempt limit  */
exports.retryFn = async ({ fn, attempt = 3, delay = 1000, err = null }) => {
  if (!attempt)
    return Promise.reject(err)
  await exports.sleep(delay)
  return fn().catch(err => {
    attempt -= 1
    return exports.retryFn({ fn, attempt, delay, err })
  })
}

/**
 * It can be used to mock a entire function and return the "expectedResult" or,
 * the function will be called as normal and return the parameters from that called.
 * @param {*} expectedResult 
 * @returns 
 */
exports.funcReturnExpectedOrNativeParam = expectedResult => {
  return nativeParam => {
    return Promise.resolve(typeof expectedResult !== "undefined" ? expectedResult : nativeParam)
  }
}

const cloneObjectOptions = {
  ignoreProps: [],
  ignorePropsPartialMatch: false
}
/** a better way to deep clone a object. A new object is created without references. Functions won't be cloned. 
 * @template T
 * @param {T} aObject 
 * @param {Object} options 
 * @param {Array<String>} options.ignoreProps Array of property names to ignore during the cloning. Names should full match with property name. It also may contain part of a property name when combined with ignorePropsPartialMatch true
 * @param {Boolean} options.ignorePropsPartialMatch indicate to partial match the names on ignoreProps
* @return {T}
 */
exports.cloneObjectV2 = (aObject, options = cloneObjectOptions) => {
  if (!options) options = {}

  // Prevent undefined objects
  // if (!aObject) return aObject;

  const bObject = Array.isArray(aObject) ? [] : {}

  let value
  for (const propName in aObject) {

    if (options.ignoreProps) {

      const includes = options.ignoreProps.some(ignoreProp => propName.includes(ignoreProp))
      if (includes) continue
    }

    // Prevent self-references to parent object
    if (Object.is(aObject[propName], aObject)) continue

    value = aObject[propName]
    if (value == null)
      bObject[propName] = value
    else
      bObject[propName] = (typeof value === "object") ? exports.cloneObjectV2(value, options) : value
  }

  return bObject
}

/** Return the ordinal number
 * @param {Number} number 
 * @returns {String} 
 */
exports.ordinal = number => {
  const label = ["th", "st", "nd", "rd"]
  const remainder = number % 100
  return number + (label[(remainder - 20) % 10] || label[remainder] || label[0])
}

/** Return value of a property based on string
 * @param {Object} obj  
 * @param {String} deepProp this can be nested prop as "prop1.prop2"  
 */
exports.getPropDeepValue = (obj, deepProp) => {
  return deepProp.split(".").reduce((prev, curr) => prev && prev[curr], obj)
}

/** set value in obj property 
 * @param {Object} obj  
 * @param {Object} deepProp this can be nested prop as "prop1.prop2"  
 * @param {Any} value  
 */
exports.setPropDeepValue = (obj, deepProp, value) => {
  const [prop, ...rest] = deepProp.split(".")
  obj[prop] = rest.length ? exports.setPropDeepValue(obj[prop], rest.join("."), value) : value
  return obj
}

const amps = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&#39;": "'",
  "&quot;": '"'
}

/** Replace ampersand coding to actual character.
 * @param {String} str */
exports.ampDecode = str => {
  let newStr = str
  Object.keys(amps).forEach(key => {
    var regex = new RegExp(key, 'g')
    newStr = newStr.replace(regex, amps[key])
  })
  return newStr
}

/** Replace character to ampersand coding when possible.
 * @param {String} str */
exports.ampEncode = str => {
  let newStr = str
  Object.keys(amps).forEach(key => {
    var regex = new RegExp(amps[key], 'g')
    newStr = newStr.replace(regex, key)
  })
  return newStr
}

exports.appBuildNumberDisplay = () => {
  const minor = process.env.BUILD_NUMBER || new Date().toISOString().split(':').slice(0, 2).join(':') // similar to "2024-03-19T11:07"
  return "1." + minor
}

/** Return the value of a "--argument"
 * @param {Array<String>} args
 * @param {String} argName
 */
exports.getArgValue = (args, argName) => {
  if (args.length > 0) {
    const index = args.findIndex(arg => arg.includes(`--${argName}`))
    if (index > -1)
      return args[index].split('=')[1]
  }
  return null
}

/** Inject a value on a file based on a placeholder 
 * @param {Object} params
 * @param {Object} params.fs // file system NodeJs module
 * @param {String} params.batPath
 * @param {String} params.placeHolder
 * @param {String} params.value
*/
exports.injectOnFile = ({ fs, batPath, placeHolder, value }) => {
  let content = fs.readFileSync(batPath, 'utf8')
  content = content.replace(new RegExp(placeHolder, 'g'), value)
  fs.writeFileSync(batPath, content)
}

exports.timedGreetings = () => {
  const now = new Date()
  const hour = now.getHours()
  if (hour < 12) return "Good morning"
  if (hour < 18) return "Good afternoon"
  return "Good evening"
}