<template>
  <v-container class="CredentialForm" fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-1 mx-auto cardMain">
          <slot name="logoImage"></slot>
          <v-form ref="credentialFrm" class="credentialFrm" lazy-validation @submit.prevent>
            <!-- 
              <v-tabs center-active centered v-model="tabAppCurrentIndex">
              <v-tab v-for="app in tabAppList" :class="app.btn.class" :key="app.index">
                <span v-text="app.btn.text" />
              </v-tab>
            </v-tabs> -->

            <!-- <v-tabs center-active centered @change="onTabActionChange" v-model="tabActionCurrentIndex">
              <template v-for="action in tabActionList">
                <v-tab v-if="action.show ? action.show() : true" :class="[action.btn.class]" :key="action.index">
                  <span v-text="action.btn.text" />
                </v-tab>
              </template>
            </v-tabs> -->

            <v-tabs-items class="tabsAction" v-model="tabActionCurrentIndex" v-if="!forgotPasswordConfirmationShow && !newPasswordRequiredShow" touchless>
              <v-tab-item :key="tabActionLogin.index">
                <v-card-text>
                  <v-text-field
                    v-model="userEmail"
                    :rules="inputRules('email')"
                    :disabled="loadingAny"
                    ref="inputUserEmail"
                    class="inputUserEmail"
                    type="email"
                    label="E-mail"
                    placeholder="email@address.com"
                    validate-on-blur
                    required
                  />
                  <br />
                  <PasswordField
                    v-model="userPassword"
                    :rules="inputRules('required', 'Password')"
                    :disabled="loadingAny"
                    ref="inputUserPassword"
                    class="inputUserPassword"
                    label="Password"
                    placeholder="*******"
                    validate-on-blur
                    required
                  />

                  <v-card-actions>
                    <v-btn class="btnForgotPassword" :disabled="loadingAny" @click="forgotPassword"> Forgot password </v-btn>
                    <v-btn class="btnEnter" type="submit" :disabled="loadingAny" @click="signIn" color="primary"> Enter </v-btn>
                  </v-card-actions>
                  <v-container v-if="tabAppCurrentIndex === tabAppBusiness.index">
                    <br />
                    <span class="text--secondary">Need an Account?</span>
                    <v-btn
                      outlined
                      small
                      color="primary"
                      class="borderless"
                      :class="tabActionSigUp.btn.class"
                      @click="tabActionCurrentIndex = tabActionSigUp.index"
                      :disabled="loadingAny"
                    >
                      Sign up
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-tab-item>

              <v-tab-item :key="tabActionSigUp.index">
                <v-card-text>
                  <v-text-field
                    v-model="userEmail"
                    ref="inputUserEmailSignup"
                    class="inputUserEmailSignup"
                    type="email"
                    label="E-mail"
                    placeholder="email@address.com"
                    required
                    :rules="inputRules('email')"
                    :disabled="loadingAny || userNeedsConfirm"
                    validate-on-blur
                  />
                  <PasswordField
                    v-model="userPassword"
                    ref="inputUserPasswordSignup"
                    class="inputUserPasswordSignup"
                    label="Password"
                    placeholder="*******"
                    :rules="inputRules('userPasswordSetup')"
                    :disabled="loadingAny || (userNeedsConfirm && !userNeedsConfirmPsw)"
                    :passwordHint="passwordHint"
                    validate-on-blur
                    required
                  />
                  <v-text-field
                    ref="inputUserNameSignup"
                    class="inputUserNameSignup"
                    :rules="inputRules('userName')"
                    :disabled="loadingAny"
                    v-model="userName"
                    label="Your Name"
                    placeholder="Your Name"
                    required
                    validate-on-blur
                  />

                  <BusinessProfileFields class="ma-0 pa-0" v-model="businessProfile" :loadingOthers="loadingAny" hideLogo />

                  <v-card-actions v-if="!confirmationCodeSent">
                    <v-btn
                      class="btnSendConfirmationCode"
                      :type="isSignUpToConfirm ? 'button' : 'submit'"
                      :disabled="loadingAny"
                      @click="signUpSendConfirmationCode"
                      color="primary"
                    >
                      Send Confirmation code
                    </v-btn>
                  </v-card-actions>
                  <div v-if="confirmationCodeSent" class="confirmationCodeSent">
                    <v-text-field
                      :disabled="loadingAny"
                      :rules="inputRules('required')"
                      ref="inputUserConfirmationCode"
                      class="inputUserConfirmationCode"
                      type="text"
                      label="Confirmation code"
                      placeholder="Enter confirmation code"
                      validate-on-blur
                      required
                    />

                    <v-card-actions>
                      <v-btn class="btnResendConfirmationCode" :disabled="loadingAny" @click="resendCode"> Resend code </v-btn>

                      <v-btn class="btnConfirm" :disabled="loadingAny || !userNeedsConfirm" @click="confirmCode" type="submit" color="primary"> Create Account </v-btn>
                    </v-card-actions>
                  </div>
                  <v-container>
                    <br />
                    <span class="text--secondary">Already have account?</span>
                    <v-btn
                      outlined
                      small
                      color="primary"
                      class="borderless"
                      :class="tabActionLogin.btn.class"
                      @click="tabActionCurrentIndex = tabActionLogin.index"
                      :disabled="loadingAny"
                    >
                      Login
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>

            <div v-if="forgotPasswordConfirmationShow">
              <v-card-text>
                <v-text-field
                  :rules="inputRules('required', 'Confirmation code')"
                  :disabled="loadingAny"
                  ref="inputUserConfirmationCode"
                  class="inputUserConfirmationCode"
                  type="text"
                  label="Confirmation code"
                  placeholder="Enter confirmation code"
                  validate-on-blur
                  required
                />
                <PasswordField
                  v-model="userPassword"
                  :rules="inputRules('userPasswordSetup')"
                  :disabled="loadingAny"
                  :passwordHint="passwordHint"
                  ref="inputUserPasswordConfirmation"
                  class="inputUserPasswordConfirmation"
                  label="New Password"
                  placeholder="*******"
                  validate-on-blur
                  required
                />

                <v-card-actions>
                  <!-- rename to class="forgotPasswordConfirmationCancel"  -->
                  <v-btn class="forgotPasswordConfirmationShow" @click="forgotPasswordConfirmationShow = false" :disabled="loadingAny"> Cancel </v-btn>
                  <!-- rename to class="forgotPasswordConfirmationConfirm"  -->
                  <v-btn class="btnConfirm" :disabled="loadingAny" @click="confirmCode" type="submit" color="primary"> Confirm </v-btn>
                </v-card-actions>
              </v-card-text>
            </div>

            <div v-if="newPasswordRequiredShow">
              <v-card-text>
                <PasswordField
                  v-model="userPassword"
                  :rules="inputRules('userPasswordSetup')"
                  :disabled="loadingAny"
                  :passwordHint="passwordHint"
                  ref="inputUserNewPassword"
                  class="inputUserNewPassword"
                  label="Enter your new password"
                  placeholder="*******"
                  validate-on-blur
                  required
                />
                <v-card-actions>
                  <v-btn class="newPasswordRequiredShow" @click="newPasswordRequiredShow = false" :disabled="loadingAny"> Cancel </v-btn>
                  <v-btn class="btnConfirm" type="submit" @click="confirmNewPassword" :disabled="loadingAny" color="primary"> Confirm </v-btn>
                </v-card-actions>
              </v-card-text>
            </div>

            <Credential
              ref="credential"
              :appName="appNameByTabAppIndex(tabAppCurrentIndex)"
              :cognitoUser="cognitoUser"
              :skipLocalLogin="skipLocalLogin"
              :api="api"
              @userNeedsConfirm="onUserNeedsConfirm"
              @snackbar="$emit('snackbar', $event)"
              @modal="$emit('modal', $event)"
              @logged="$emit('logged', $event)"
              @cognito="$emit('cognito', $event)"
              @loggedOut="$emit('loggedOut')"
              @passwordUpdated="onPasswordUpdated"
              @forgotPasswordVerificationCode="onForgotPasswordVerificationCode"
              @newPasswordRequired="onNewPasswordRequired"
              @loading="onLoadingUpdate"
            />
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import rules from "../../server/models/rules"
import { appTypeBusiness, appTypeClient, BusinessProfile } from "../../server/models/types"
import Credential from "./Credential.vue"

export default {
  name: "CredentialForm",
  props: {
    api: {
      type: Object,
      default: null,
    },
    appName: {
      type: String,
      default: "",
    },
    cognitoUser: {
      type: Object,
      default: null,
    },
    skipLocalLogin: {
      type: Boolean,
      default: false,
    },
    loadingOthers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const tabAppBusiness = {
      index: 0,
      name: appTypeBusiness,
      btn: {
        class: "btnTabUserTypeBusiness",
        text: "I'm Business",
      },
    }
    const tabAppClient = {
      index: 1,
      name: appTypeClient,
      btn: {
        class: "btnTabUserTypeClient",
        text: "I'm Client",
      },
    }
    const tabActionLogin = {
      index: 0,
      name: "login",
      btn: {
        class: "btnTabLogin",
        text: "Login",
      },
      vif: () => true,
    }
    const tabActionSigUp = {
      index: 1,
      name: "signUp",
      btn: {
        class: "btnTabSignUp",
        text: "Sign up",
      },
      show: () => this.tabAppCurrentIndex === tabAppBusiness.index,
    }

    return {
      tabAppBusiness,
      tabAppClient,
      tabAppList: [tabAppBusiness, tabAppClient],
      tabAppCurrentIndex: tabAppClient.index,
      tabActionLogin,
      tabActionSigUp,
      forgotPasswordConfirmationShow: false,
      newPasswordRequiredShow: false,
      tabActionList: [tabActionLogin, tabActionSigUp],
      tabActionCurrentIndex: tabActionLogin.index,
      userEmail: "",
      userPassword: "",
      userName: "",
      businessProfile: new BusinessProfile(),
      loading: false,
      passwordHint: "At least 12 characters, including numbers and special characters",
    }
  },
  components: {
    Credential,
    BusinessProfileFields: () => import("./BusinessProfileFields.vue"),
    PasswordField: () => import("./PasswordField.vue"),
  },
  created() {
    this.resetData()
    this.tabAppCurrentIndex = this.tabAppBusiness.index
    this.tabActionCurrentIndex = this.tabActionLogin.index
    window.localStorage["businessProfileSignUp"] = ""
  },
  computed: {
    loadingAny() {
      return this.loadingOthers || this.loading
    },
    userNeedsConfirm() {
      return this.cognitoUser?.userNeedsConfirm
    },
    userNeedsConfirmPsw() {
      return this.cognitoUser?.userNeedsConfirmPsw
    },
    confirmationCodeSent() {
      return this.cognitoUser?.confirmationCodeSent
    },
    isSignUpToConfirm() {
      return this.tabActionCurrentIndex === this.tabActionSigUp.index && this.userNeedsConfirm && this.userNeedsConfirmPsw
    },
  },
  watch: {
    tabActionCurrentIndex() {
      this.onTabActionChange()
    },
    tabAppCurrentIndex() {
      this.onTabAppCurrentIndexChange()
    },
  },
  mounted() {
    setTimeout(() => {
      const els = document.querySelectorAll("input:-webkit-autofill")
      els.forEach((el) => {
        const label = el.parentElement.querySelector("label")
        label.classList.add("v-label--active")
      })
    }, 500)

    this.$refs.credential.doLogout()
  },
  methods: {
    appNameByTabAppIndex(index) {
      const tab = this.tabAppList.find((tab) => tab.index === index)
      return tab?.name || this.tabAppBusiness.name
    },
    tabAppByAppName(name) {
      return this.tabAppList.find((tab) => tab.name === name)
    },
    tabAppByIndex(index) {
      return this.tabAppList.find((tab) => tab.index === index)
    },
    async onTabAppCurrentIndexChange() {
      if (this.tabAppCurrentIndex === this.tabAppClient.index) {
        this.tabActionCurrentIndex = this.tabActionLogin.index
      }
    },
    async onTabActionChange() {
      const returnToSignUp = () => {
        return new Promise((resolve) => {
          const callback = (confirmed) => resolve(confirmed)
          this.$emit(
            "modal",
            {
              id: "leavingSignUp",
              title: "Leaving Sign up",
              content: `Your Sign Up is not completed yet. Leaving the Sign up tab will clear the entered data. Do you want to return to Sign up and finish your account creation?`,
              btnOkText: "Return to Sign up",
            },
            callback,
          )
        })
      }

      if (
        this.tabActionCurrentIndex === this.tabActionLogin.index &&
        (this.cognitoUser?.userNeedsConfirm || this.cognitoUser?.userNeedsConfirmPsw || this.cognitoUser?.confirmationCodeSent)
      ) {
        const returnToSignUpConfirmation = await returnToSignUp()
        if (returnToSignUpConfirmation) {
          this.$nextTick(() => {
            this.tabActionCurrentIndex = this.tabActionSigUp.index
          })
          return
        }
        await this.resetAll()
      }
    },
    onUserNeedsConfirm() {
      this.tabActionCurrentIndex = this.tabActionSigUp.index
    },
    inputRules(dataName, label) {
      if (dataName === "required") return [rules({ label }).required]
      if (dataName === "userName") return [rules({ label: "Name" }).required]
      if (dataName === "email") return [rules({ label: "E-mail" }).required, rules().email]
      if (dataName === "userPasswordSetup") return [rules({ label: "Password" }).strongPassword]
      return []
    },
    async forgotPassword() {
      if (!this.$refs.inputUserEmail.validate()) return
      this.forgotPasswordConfirmationShow = true
      this.userPassword = ""
      await this.$refs.credential.forgotPassword(this.userEmail)
      this.$nextTick(() => (this.$refs.inputUserConfirmationCode.$refs.input.value = ""))
    },
    onPasswordUpdated() {
      this.forgotPasswordConfirmationShow = false
      this.newPasswordRequiredShow = false
      this.userPassword = ""
      this.$nextTick(() => (this.tabActionCurrentIndex = this.tabActionLogin.index))
    },
    onLoadingUpdate(value) {
      this.loading = value
    },
    onForgotPasswordVerificationCode() {
      this.forgotPasswordConfirmationShow = true
      this.userPassword = ""
    },
    onNewPasswordRequired() {
      this.newPasswordRequiredShow = true
      this.userPassword = ""
    },
    confirmNewPassword() {
      this.$refs.credential.confirmNewPassword(this.userPassword)
    },
    signIn() {
      if (!this.$refs.credentialFrm.validate()) return
      this.$refs.credential.doSignIn({
        email: this.userEmail,
        password: this.userPassword,
        appName: this.appNameByTabAppIndex(this.tabAppCurrentIndex),
      })
    },
    signUpSendConfirmationCode() {
      if (!this.$refs.credentialFrm.validate()) return
      this.$refs.credential.signUpSendConfirmationCode({
        email: this.userEmail,
        password: this.userPassword,
      })
    },
    confirmCode() {
      if (!this.$refs.credentialFrm.validate()) return
      if (this.tabAppCurrentIndex === this.tabAppBusiness.index && this.tabActionCurrentIndex === this.tabActionSigUp.index)
        window.localStorage["businessProfileSignUp"] = JSON.stringify({ uName: this.userName, bPublic: { ...this.businessProfile.bPublic } })

      this.$refs.credential.confirmCode({
        confirmationCode: this.$refs.inputUserConfirmationCode.$refs.input.value,
        password: this.userPassword,
      })
    },
    resendCode() {
      this.$refs.credential.resendCode({})
    },
    async resetAll() {
      this.$refs.credential.doLogout()
      await this.resetData()
    },
    resetData() {
      return new Promise((resolve) => {
        this.$emit("cognito", null)
        this.tabAppCurrentIndex = this.tabAppBusiness.index
        this.tabActionCurrentIndex = this.tabActionLogin.index
        this.forgotPasswordConfirmationShow = false
        this.newPasswordRequiredShow = false
        this.userEmail = ""
        this.userPassword = ""
        this.userName = ""
        this.businessProfile = new BusinessProfile()
        this.$nextTick(resolve)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.cardMain {
  width: 650px;
  border-radius: 10px;
}

.tabAppCurrentTitle {
  text-align: left;
}

.CredentialForm ::v-deep {
  .BusinessProfileFields .CardPanel {
    margin: 0 !important;
    border: none !important;
  }
}
</style>