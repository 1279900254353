const axios = require("axios")
const { AxiosInstance } = require("axios")  // eslint-disable-line no-unused-vars
const { appRequiresUpdate, appRequireLogin } = require("./../../server/models/types")

class ApiAxios {
  /** version of the tooling using the ApiAxios */
  appVersion = process.env.VUE_APP_APPBUILDNUMBER.trim()

  /** @type {AxiosInstance} */
  api = null

  /** @type {CognitoUser} */
  apiCognitoUser = null

  apiCallList = []

  /** 
   * @param {String} baseURL 
   * @param {Object} options 
   * @param {Object} options.headers object that replaces default header
   * @param {Object} options.create object that complement Axios create
  */
  constructor(baseURL, options) {
    if (!options) options = {}

    this.api = axios.create({
      baseURL,
      withCredentials: false, // This is the default
      headers: options.headers || {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      ...(options.create || {})
    })

    if (process.env.VUE_APP_APPDOMAIN === "goclientconnect.com")
      this.apiCallList = null
  }

  /** 
 * @param {Object} params
 * @param {CognitoUser} params.cognitoUser
 * @param {Object} params.vueApp
 */
  setup({ cognitoUser, vueApp }) {
    this.apiCognitoUser = cognitoUser
    this.vueApp = vueApp
    if (this.apiCallList)
      window.apiCallList = this.apiCallList
  }

  static createAxiosConfig(config) {
    const onProgress = progressEvent => {
      return Math.floor(progressEvent.loaded / progressEvent.total * 100)
    }

    const onUploadProgress = progressEvent => {
      if (!config.onUploadProgress) return
      const progress = onProgress(progressEvent)
      config.onUploadProgress(progress)
    }

    const onDownloadProgress = progressEvent => {
      if (!config.onDownloadProgress) return
      const progress = onProgress(progressEvent)
      config.onDownloadProgress(progress)
    }

    return {
      onUploadProgress,
      onDownloadProgress
    }
  }

  vueAppUpdatingCounterKey = "vueAppUpdatingCounter"

  vueAppUpdatingCounterReset() {
    if (!window) return
    const counter = 0
    window.localStorage[this.vueAppUpdatingCounterKey] = counter
    return counter
  }

  vueAppUpdatingCounterInc() {
    if (!window) return
    const counterStr = window.localStorage[this.vueAppUpdatingCounterKey]
    const counter = (counterStr ? parseInt(counterStr) : 0) + 1
    window.localStorage[this.vueAppUpdatingCounterKey] = counter
    return counter
  }

  async loginAgain(message) {
    const isTokeExpired = message.includes("token has expired")
    const title = isTokeExpired ? "Session Timed Out" : "Login Required"
    const content = isTokeExpired ? `For your protection, your session has timed out` : message

    if (this.vueApp) {
      await this.vueApp.$root.modal({
        id: "loginAgain",
        title,
        content: `${content}. Please, login again.`,
        btnOkText: "Go to Login",
        btnCancelShow: false,
      })

      return await this.vueApp.$root.$el.__vue__.logoutAndLogin()
    }
  }

  async vueAppUpdating(vueAppUpdatingWithoutModal) {

    const counter = this.vueAppUpdatingCounterInc()
    if (counter > 3) {
      this.vueAppUpdatingCounterReset()
      const message = `We're sorry, but the app update encountered an issue. Kindly refresh the page manually to resolve it.`
      console.warn(message)
      if (this.vueApp)
        return await this.vueApp.$root.modal({
          id: "vueAppUpdatingFailed",
          title: "App update failed",
          content: message,
          btnOkText: "Ok",
          btnCancelShow: false,
        })
      if (window)
        return window.alert(message)

      return
    }

    if (this.vueApp && !vueAppUpdatingWithoutModal) {
      await this.vueApp.$root.modal({
        id: "vueAppUpdating",
        title: "App requires an update",
        content: `Click "OK" to proceed with the automatic update.`,
        btnOkText: "Update App",
        btnCancelShow: false,
      })
    }
    //during Jenkins testing window is not defined 
    if (window) {
      console.log(`reloading...`)
      window.location.reload() // force the window to refresh and fetch all existing requests
    }
  }

  /**
   * @param {String} path 
   * @param {String} eventName 
   * @param {Object} params sent as JSON 
   * @param {Object} [options] 
   * @param {Boolean} [options.skipAuth] 
   * @param {Boolean} [options.vueAppUpdatingWithoutModal] 
   * @returns 
   */
  async apiPost(path, eventName, params, options) {
    if (!params) params = {}
    if (!options) options = {}
    const { skipAuth, vueAppUpdatingWithoutModal } = options
    const headers = {}
    if (!skipAuth) {
      if (!this.apiCognitoUser) throw new Error(`Api has not cognitoUser.`)
      await this.apiCognitoUser.userTokenValidation(this.apiCognitoUser)
      headers.Authorization = this.apiCognitoUser?.signInUserSession?.idToken?.jwtToken
    }
    const result = await this.api.post(path, JSON.stringify({ eventName, appVersion: this.appVersion, ...params }), { headers })
      .catch(async err => {
        const code = err?.response?.status
        const message = err?.response?.data?.message || err?.response || err
        const reqStream = err.response.headers["req-stream"]
        const reqStreamId = err.response.headers["req-stream-id"]

        if (code >= 400 && code < 500)
          return await this.loginAgain(message)

        if (process.env.VUE_APP_APPDOMAIN !== "goclientconnect.com")
          console.warn(`apiPost ~ eventName: ${eventName} ~ at: ${new Date().toISOString()} ~ reqStream: ${reqStream} ~ reqStreamId: ${reqStreamId}`)

        if (message === appRequiresUpdate)
          return await this.vueAppUpdating(vueAppUpdatingWithoutModal)

        if (message === appRequireLogin)
          return await this.loginAgain()

        const supportCodes = `
        <b>Support Codes:</b><br/> 
        Stream: ${reqStream}<br/>
        StreamId: ${reqStreamId}<b/>`

        return { ok: false, message, supportCodes }
      })

    const ok = typeof result.ok !== "undefined" ? Boolean(result.ok) : true
    const apiResult = { ok, message: String(result.message), data: result.data, ...result }

    if (this.apiCallList)
      this.apiCallList.push({
        dateTime: new Date().toISOString(),
        eventName, ...apiResult
      })

    return apiResult
  }

  instance() {
    return this.api
  }
}

module.exports = { ApiAxios }