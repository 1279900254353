"use strict"

/**
 * rules to validate Inputs
 * @param {Object} config 
 * @param {String} [config.label] custom label to display on default error message 
 * @param {String} [config.errorMessage] custom error message to replace default error message
 * @returns 
 */
const rules = config => {
  if (!config) config = { label: "", errorMessage: "" }
  if (!config.label) config.label = "Field"

  const required = value => {
    const errorMessage = `${config.label} required`
    const hasValue = Boolean(value)
    if (hasValue && typeof value === "object")
      return Object.keys(value).length > 0 || errorMessage
    else
      return hasValue || errorMessage
  }

  const email = value => !value || Boolean(value && value.match(/^\S+@\S+\.\S+$/)) || config.errorMessage || "Not a valid as e-mail" //this "/^\S+@\S+\.\S+$/" will check if there's <letter>@<domain>.<letter> no spaces,
  const min = min => value => value >= min || config.errorMessage || `${config.label} cannot be less than ${min}`
  const max = max => value => value <= max || config.errorMessage || `${config.label} cannot be longer than ${max}`
  const minLengthChars = min => value => (value || "").length >= min || config.errorMessage || `${config.label} cannot be less than ${min} characters`
  const maxLengthChars = max => value => (value || "").length <= max || config.errorMessage || `${config.label} cannot be longer than ${max} characters`
  const minLengthSelected = min => value => (value || "").length >= min || config.errorMessage || `${config.label} cannot have less than ${min} selected`
  const maxLengthSelected = max => value => (value || "").length <= max || config.errorMessage || `${config.label} cannot have more than ${max} selected`
  const hasNumber = value => !value || Boolean(value && value.match(/\d/)) || config.errorMessage || `${config.label} must contain at least one number`
  const hasSpecialChar = value => !value || Boolean(value && value.match(/[^a-zA-Z0-9]/)) || config.errorMessage || `${config.label} must contain at least one special character`
  const strongPassword = value => {
    const requiredPassword = required(value)
    if (requiredPassword !== true) return requiredPassword
    const minLength = minLengthChars(12)(value)
    if (minLength !== true) return minLength
    const hasNumberPassword = hasNumber(value)
    if (hasNumberPassword !== true) return hasNumberPassword
    const hasSpecialCharPassword = hasSpecialChar(value)
    if (hasSpecialCharPassword !== true) return hasSpecialCharPassword
    return true
  }

  return {
    required,
    email,
    min,
    max,
    minLengthChars,
    maxLengthChars,
    minLengthSelected,
    maxLengthSelected,
    hasNumber,
    hasSpecialChar,
    strongPassword
  }
}


module.exports = rules 