var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LoginApp", class: { logged: _vm.logged } },
    [
      _c(
        "v-app",
        {
          style: {
            background: _vm.$vuetify.theme.themes[_vm.theme].background,
          },
        },
        [
          _c(
            "v-main",
            { staticClass: "loginAppMain" },
            [
              _c("CredentialForm", {
                staticClass: "credentialForm",
                attrs: {
                  appName: _vm.$root.appName,
                  cognitoUser: _vm.$root.cognitoUser,
                  loadingOthers: _vm.logged,
                  api: _vm.api,
                  skipLocalLogin: "",
                },
                on: {
                  snackbar: _vm.$root.snackbar,
                  modal: _vm.$root.modal,
                  logged: _vm.onLogged,
                  cognito: _vm.onCognito,
                },
                scopedSlots: _vm._u([
                  {
                    key: "logoImage",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "logoWrapper" },
                          [
                            _vm.bPublic && _vm.bPublic.bLogoImage
                              ? _c("v-card-text", [
                                  _c("img", {
                                    staticClass: "loginLogo bLogoImage",
                                    attrs: {
                                      alt: "Company Logo",
                                      src: _vm.bPublic.bLogoImage,
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "bLocationName text-h5",
                                    domProps: {
                                      textContent: _vm._s(_vm.bPublic.bName),
                                    },
                                  }),
                                  _vm.bLocationAddress
                                    ? _c("div", {
                                        staticClass:
                                          "bLocationAddress text--secondary",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.bLocationAddress
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _c("img", {
                                  staticClass: "loginLogo",
                                  attrs: {
                                    alt: "Company Logo",
                                    src: require("@/../../../img/goClientConnect-600px.png"),
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("div", { staticClass: "appStatusInner" }, [
                _c("div", {
                  staticClass: "appVersion",
                  domProps: { textContent: _vm._s(_vm.$root.appVersion) },
                }),
              ]),
            ],
            1
          ),
          _c("Dialog", { ref: "dialog" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }